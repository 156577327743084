gsap.registerPlugin(ScrollTrigger,SplitText);

var isRTL = ($('html').attr('dir') == "rtl") ? true : false,
	winWidth = $(window).width(),
	winHeight = $(window).height();

var eventClick = false;

pageLoadTL = gsap.timeline({paused: true});

heading = document.querySelector("[data-split-words][data-animation-transition]"),
splitWords = new SplitText(heading, { type: "words", wordsClass: "single-word" }),

heading2 = document.querySelectorAll("[data-split-words][data-animation-scroll]"),
splitWords2 = new SplitText(heading2, { type: "words", wordsClass: "single-word" }),

$('[data-split-words] .single-word').wrapInner('<div class="single-word-inner">');

var match_Media = gsap.matchMedia();

function scrollAnimation(){
    if($(window).width() >= 768){
    if (heading2) {
        $(heading2).each(function () {
            let delay = $(this).data('delay') || 0;
          let triggerElement = $(this);
          let targetElement = $(this).find('.single-word-inner');
          let tl = gsap.timeline({
            scrollTrigger: {
              trigger: triggerElement,
              start: "0% 90%",
              end: "100% 0%",
              toggleActions: "play none none none",
              once: true
            }
          });
    
          tl.fromTo(targetElement, {
            yPercent: 120,
            rotate: 0.001,
            ease: "primary-ease",
            duration: 0.5,
          }, {
            yPercent: 0,
            rotate: 0.001,
           
           
            stagger: 0.15,
            // clearProps: "all",
            autoAlpha: 1,
            delay: delay,
          });
        });
      }

      if (document.querySelectorAll('[data-animation-scroll="fadeInUp"]')) {
        $('[data-animation-scroll="fadeInUp"]').each(function () {
          let triggerElement = $(this);
          let targetElement = $(this);

          let delay = $(this).data('delay') || 0;
    
          let tl = gsap.timeline({
            scrollTrigger: {
              trigger: triggerElement,
              start: "0% 90%",
              end: "100% 0%",
              toggleActions: "play none none none"
            }
          });
    
          tl.fromTo(targetElement, {
            y: "1em",
            rotate: 0.001,
            autoAlpha: 0
          }, {
            y: 0,
            rotate: 0.001,
            autoAlpha: 1,
            ease: "primary-ease",
            duration: 0.2,
            stagger: 0.2,
            delay: delay,
            // clearProps: "all"
          }, 0.2);
        });
      }


    //   fade in right
      if (document.querySelectorAll('[data-animation-scroll="fadeInRight"]')) {
        $('[data-animation-scroll="fadeInRight"]').each(function () {
          let triggerElement = $(this);
          let targetElement = $(this);

          let delay = $(this).data('delay') || 0;
    
          let tl = gsap.timeline({
            scrollTrigger: {
              trigger: triggerElement,
              start: "0% 90%",
              end: "100% 0%",
              toggleActions: "play none none none"
            }
          });
    
          tl.fromTo(targetElement, {
            x: "2em",
            rotate: 0.001,
            autoAlpha: 0,
            // duration: .5
          }, {
            x: 0,
            rotate: 0.001,
            autoAlpha: 1,
            ease: "primary-ease",
            duration: 1,
            delay: delay,
            // clearProps: "all"
          }, 0.2);
        });
      }
    }
}
function animateElements(){
   if($(".animate").length > 0){
       $(".animate").bind("inview", function(event, isInview){
           if(isInview){
               let aniamte = $(this).attr("data-animation");
               let speedDuration = $(this).attr("data-duration");
               let $t = $(this);
               setTimeout( function() {
                   $t.addClass(aniamte + " animated");
               }, speedDuration);
           }
       })
   }
}
function pageLoading() {
    if($(window).width() >= 768){
        if('[data-split-words]'){
			pageLoadTL.play().then(function () {
				scrollAnimation();
			})
        }
    }
}
$(document).ready(function () {
	browserDetect();
	ChangeToSvg();
	initializeFirstSlider();
	onMouseHover();
});

$(window).on('resize orientationchange', function () {
	// Do on resize
	winWidth = $(window).width(),
	winHeight = $(window).height();
	
	if ($('.js-initiate-link').length > 0) {
		if ($(window).width() > 600) {
			var targetSliderClass = $('.js-initiate-link.active').data('slider');
			loadInitiativeCarousel($(targetSliderClass));
		} 
		// else {
			// 	$('.current').find('.js-initiate-link.active').click();
			// }
		}
		secMapHeight();
		sliderHeight();
});
$(window).on('scroll', function () {
	$('.img-grid').mouseenter(function () {
		$(this).addClass('expand');
		$('.img-grid').not(this).addClass('contract');
	});
	
	$('.img-grid').mouseleave(function () {
		$(this).removeClass('expand');
		$('.img-grid').not(this).removeClass('contract');
	});
});
$(window).on('load', function () {
	initSliderProd();
	initMomentsCapture();
	dataSrc();
	videoPlayerInit();
	videoOnHover();
	closeVideoOnClick();
	clickVideoPopup();
	initAccordion();
	alertSlider();
	initLocationSlider();
	initIntrestSlider();
	initTipsSlider();
	initStayingSlider();
	menuOpen();
	scrollTop();
	eventList();
	initovernightSlider();
	intAccordionTabs();
	initializeAOS();
	initVenueSlider();
	filterBar();
	scrollBar();
	scrolltoSec();
	initnewsUpdateSlider();
	if($(window).width() > 991){
		accordionhoverState();
	} else{
		accordionClickState();
	}
	setTimeout(function () {
		$("html").addClass("dom-ready");
	  }, 200);
	if($(window).width() > 991){
		accordionhoverState();
	} else{
		accordionClickState();
	}
	if($(window).width() > 991){
		accordionhoverState();
	} else{
		accordionClickState();
	}
	$('.tab-items').click(function() {
		var tabId = $(this).data('tab');
        $('.tab-items').removeClass('active');
        $('.tab-pane').removeClass('active');
        $(this).addClass('active');
        $('#' + tabId).addClass('active');
    });
	$('.dropdown-item').first().addClass('active');
	var selectedValue = $('.dropdown-item.active').text();
	$('.last-item').text(selectedValue);
	$('.js-initiate-link').on('shown.bs.tab', function (e) {
		var targetSliderClass = $(e.target).data('slider');
		if ($(targetSliderClass).hasClass('slick-initialized')) {
			$(targetSliderClass).slick('setPosition');
			var $arrowWrap = $(targetSliderClass).parent().find(".arrow-wrap");
			$arrowWrap.removeClass('noSlider--pagination');
		} else {
			loadInitiativeCarousel($(targetSliderClass));
		}
	});
	if ($('.js-initiate-link').length > 0) {
		if ($(window).width() > 601) {
			var targetSliderClass = $('.js-initiate-link.active').data('slider');
			loadInitiativeCarousel($(targetSliderClass));
		} else {
			if (eventClick == false) {
				$('.js-initiate-link.active').click();
				eventClick = true;
			}
		}
	}
	$('.js-initiate-link').click(function () {
		if ($(window).width() < 601) {
			$('.js-initiate-link').removeClass("active");
			var currentSlider = $('.js-initiate-link').attr('data-slider');
			var sliderExists = $(currentSlider + '.slick-initialized').length;
			if (sliderExists == 0) {
				setTimeout(function () {
					loadInitiativeCarousel(currentSlider);
				}, 160);
			}
		}
	});
	// Dropdown menu toggle functionality
	$('.dropdown').on('click', function (e) {
		e.preventDefault();
		$('.dropdown-menu').toggleClass('current');
		$('body').toggleClass('dropdown-open');
	});
	
	// Click event on dropdown items to activate respective tab and slider
	$('.dropdown-item').on('click', function () {
		$('.dropdown-item').removeClass('active'); // Remove 'active' from all items
		$(this).addClass('active'); // Add 'active' to the selected item
		
		var selectedValue = $(this).text();
		$('.last-item').text(selectedValue); // Update displayed value

		var sliderSelector = $(this).data('slider'); // Get the corresponding slider selector
		var $tabPane = $(sliderSelector).closest('.tab-pane'); // Target the correct tab-pane
		var $slider = $tabPane.find('.sec-slider1'); // Target the slider within the tab-pane

		// Activate the corresponding tab-pane
		$('.tab-pane').removeClass('show active');
		$tabPane.addClass('show active');
		
		// Initialize or re-initialize the slider
		if ($slider.hasClass("slick-initialized")) {
			$slider.slick("unslick"); // Destroy the existing slider instance
		}
		loadInitiativeCarousel($slider); // Initialize the slider in the active tab-pane
	});
	// List & Grid View
	$('.js-view').on('click', function (e) {
		if ($(this).hasClass('js-grid')) {
			$('.item-wrapper ul').removeClass('list-view').addClass('grid-view');
		} else if ($(this).hasClass('js-list')) {
			$('.item-wrapper ul').removeClass('grid-view').addClass('list-view');
		}
	});
	animateElements();
	pageLoading();
	scrollAnimation();
	initLwBoxHover();
	initlwSlider();
	sliderHeight();
	scrolltosection();
	jsMapBox();
	initNewsletterPopup();
	secMapHeight();
});
$(document).keyup(function (e) {
	if (e.keyCode == 27) {
		//Do on ESC press
	}
});

function initializeAOS() {
	AOS.init({
	  once: true,
	});
}

function browserDetect() {
	navigator.sayswho = (function () {
		var ua = navigator.userAgent,
			tem,
			M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
		if (/trident/i.test(M[1])) {
			tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
			return 'IE ' + (tem[1] || '');
		}
		if (M[1] === 'Chrome') {
			tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
			if (tem != null) return tem.slice(1).join('').replace('OPR', 'Opera');
		}
		M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
		if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
		return M.join(' ');
	})();
	$('body').addClass(navigator.sayswho);
}
function ChangeToSvg() {
	$("img.js-tosvg").each(function () {
		var $img = $(this);
		var imgID = $img.attr("id");
		var imgClass = $img.attr("class");
		var imgURL = $img.attr("src");
		$.get(
			imgURL,
			function (data) {
				var $svg = $(data).find("svg");
				if (typeof imgID !== "undefined") {
					$svg = $svg.attr("id", imgID);
				}
				if (typeof imgClass !== "undefined") {
					$svg = $svg.attr("class", imgClass + " insvg");
				}
				$svg = $svg.removeAttr("xmlns:a");
				if (
					!$svg.attr("viewBox") &&
					$svg.attr("height") &&
					$svg.attr("width")
				) {
					$svg.attr(
						"viewBox",
						"0 0 " + $svg.attr("height") + " " + $svg.attr("width")
					);
				}
				$img.replaceWith($svg);
			},
			"xml"
		);
	});
}
function initializeFirstSlider() {
	if ($(".js-initiative-slider-1").length > 0) {
		const $loadInitiativeCarousel1 = $('.js-initiative-slider-1');
		if ($loadInitiativeCarousel1.length > 0) {
			loadInitiativeCarousel($loadInitiativeCarousel1);
		}
	}
}
function toggleArrowVisibility(currentSlide, slideCount, $slider) {
	var $prevArrow = $slider.parent().find('.custom-arrows.prev');
	var $nextArrow = $slider.parent().find('.custom-arrows.next');
	if (currentSlide === 0) {
		// $prevArrow.addClass('disabled');
		if (isRTL) {
			$nextArrow.addClass('disabled');
		} else {
			$prevArrow.addClass('disabled');
		}
	} else {
		// $prevArrow.removeClass('disabled');
		if (isRTL) {
			$nextArrow.removeClass('disabled');
		} else {
			$prevArrow.removeClass('disabled');
		}
	}

	if (currentSlide === slideCount - 1) {
		// $nextArrow.addClass('disabled');
		if (isRTL) {
			$prevArrow.addClass('disabled');
		} else {
			$nextArrow.addClass('disabled');
		}
	} else {
		// $nextArrow.removeClass('disabled');
		if (isRTL) {
			$prevArrow.removeClass('disabled');
		} else {
			$nextArrow.removeClass('disabled');
		}
	}
}
function loadInitiativeCarousel($slider) {
	$slider = $($slider);
	if (!$slider.length) {
		console.error("Slider element not found");
		return;
	}
	$slider.on('init', function (event, slick) {
		var totalSlides = slick.slideCount;
		totalSlidesGlobal = totalSlides;
		var $SlideArrow = $slider.closest('.tab-pane').find('.slide-arrow');
        var $pagination = $slider.closest('.tab-pane').find('.pagination-wrap');
        
        if (totalSlides < 2) {
            $SlideArrow.hide();
            $pagination.hide();
        } else {
            $SlideArrow.show();
            $pagination.show();
        }
		if (totalSlides > 0) {
			var currentSlideNumber = (slick.currentSlide + 1).toString().padStart(2, '0');
			var $paginationLabel = $slider.closest('.tab-pane').find('.js-initiative-pagination-label');

			if ($paginationLabel.length > 0) {
				$paginationLabel.html('<span class="current-slide">' + currentSlideNumber + '</span><span class="separator"> / </span><span class="total-slides">' + totalSlides.toString().padStart(2, '0') + '</span>');
			}
			toggleArrowVisibility(slick.currentSlide, slick.slideCount, $slider);
			$(slick.$slides.get([2])).addClass('slick-next-slide');
		}
	});

	$slider.slick({
			arrows: false,
			dots: false,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			fade: true,
			rtl: isRTL,
			focusOnSelect: true,
			responsive: [{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					}
				},
				{
					breakpoint: 600,
					settings: {
						dots: true,
					}
				}
			],
			customPaging: function (slider, i) {
				return '<div class="custom-pagination-dot"></div>';
			}
		})
		.on("setPosition", function (event, slick) {
			slick.$slideTrack.css("height", slick.$slideTrack.height() + "px");
		});

	// Enable custom arrows
	$slider.closest('.tab-pane').find('.js-initiative-arrow.prev').click(function () {

		if (isRTL) {
			$slider.slick('slickNext');
		} else {
			$slider.slick('slickPrev');
		}
	});

	$slider.closest('.tab-pane').find('.js-initiative-arrow.next').click(function () {

		if (isRTL) {
			$slider.slick('slickPrev');
		} else {
			$slider.slick('slickNext');
		}
	});

	// Update pagination label on beforeChange event
	$slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		var totalSlides = slick.slideCount;
		var nextSlideNumber = (nextSlide + 1).toString().padStart(2, '0');
		var $paginationLabel = $slider.closest('.tab-pane').find('.js-initiative-pagination-label');

		$paginationLabel.html('<span class="current-slide">' + nextSlideNumber + '</span><span class="separator"> / </span><span class="total-slides">' + totalSlides.toString().padStart(2, '0') + '</span>');
		toggleArrowVisibility(nextSlide, slick.slideCount, $slider);
	});

	$slider.on('afterChange', function (event, slick, currentSlide) {
		var totalSlides = slick.slideCount;
		$slider.find(".slick-slide").removeClass('slick-next-slide');
		$slider.find(".slick-active").next().addClass('slick-next-slide');

		if (totalSlides == (currentSlide + 1)) {
			$(slick.$slides.get([0])).addClass('slick-next-slide');
		}
	});
}
function initSliderProd() {
	$('.js-news-slider').each(function () {
		$(this).slick({
			autoplay: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
		});
	})
}
function initMomentsCapture() {
	$('.js-mc-slider').each(function () {
		$(this).slick({
			autoplay: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			infinite: false,
			responsive: [
				{
				  breakpoint: 992,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 601,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				  }
				} 
			  ]
		});
	})
}
function initovernightSlider() {
	$('.js-overnight-slider').each(function () {
		$(this).slick({
			autoplay: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			infinite: false,
			adaptiveHeight: true,
			responsive: [
				{
				  breakpoint: 601,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				  }
				} 
			  ]
		});
	})
}
function initStayingSlider() {
	$('.js-staying-slider').each(function () {
		$(this).slick({
			autoplay: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			infinite: false,
			adaptiveHeight: true,
			responsive: [
				{
				  breakpoint: 601,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				  }
				} 
			  ]
		});
	})
}
function initVenueSlider() {
	$(".js-venue-slider").each(function () {
	  $(this).slick({
		autoplay: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		draggable: true,
		rtl: isRTL,
		arrows: true,
		dots: false,
		infinite: false,
		responsive: [
		  {
			breakpoint: 992,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 1,
			},
		  },
		  {
			breakpoint: 601,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1,
			  dots: true,
			},
		  },
		],
	  });
	});
}
function initLocationSlider() {
	$('.js-location-slider').each(function () {
		$(this).slick({
			autoplay: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			infinite: false,
			responsive: [
				{
				  breakpoint: 992,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 601,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				  }
				} 
			  ]
		});
	})
}
function initTipsSlider() {
	$('.js-tips-slider').each(function () {
		$(this).slick({
			autoplay: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			infinite: false,
			responsive: [
				{
				  breakpoint: 992,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 601,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true
				  }
				} 
			  ]
		});
	})
}
function initIntrestSlider() {
	$('.js-intrest-slider').each(function () {
		$(this).slick({
			autoplay: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			infinite: false,
			responsive: [
				{
				  breakpoint: 992,
				  settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				  }
				},
				{
				  breakpoint: 601,
				  settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				  }
				} 
			  ]
		});
	})
}
// Video
function clickVideoPopup() {
	$("body")
		.on("click", ".js-videoPopup", function () {

			videoPopup($(this));
			separatePopup();
		})
		.on("click touchstart", ".parent-cl", function (e) {
			$(this).parents(".video-box").toggleClass("play-video");
			var etarget = $(e.target);
			if (winWidth < 1300) {
				if (etarget.parents(".video-box").hasClass("play-video")) {
					etarget[0].play();
				} else {
					etarget[0].pause();
				}
			}
		});
}
function videoPopup(target) {
	var $target = $(target);
	var videoUrl;
	var vidPlayer = null;
	if (winWidth < 600) {
		videoUrl = $target.data("mobile-url");
	} else {
		videoUrl = $target.data("desktop-url");
	}
	var videoClass = $target.data("video-class");
	var videoWidth = $target.data("width");
	var videoHeight = $target.data("height");
	var videoType = $target.data("video-type");
	var videoPoster = $target.data("video-poster") || null;
	var techOrder = ["html5", "youtube", "wistia"];
	var $content =
		'<div class="popup-video op-0"><video id="lightboxVideo" width="' +
		videoWidth +
		'" height="' +
		videoHeight +
		'" preload="auto" loop="" controls autoplay class="video-js vjs-layout-large" data-setup="{}"><source src="' +
		videoUrl +
		'" type="video/mp4" /><p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" target="_blank" rel="noopener noreferrer">supports HTML5 video</a></p></video></div>';
	$.fancybox.open({
		type: "html",
		content: $content,
		dragToClose: false,
		beforeShow: function () {
			$("html").addClass("is--videopopup");
			$("body").addClass("is--videopopup");
			$(".fancybox-container").addClass(videoClass);
		},
		afterShow: function () {
			vidPlayer = videojs("lightboxVideo", function () {
				techOrder;
			});
			vidPlayer.src({
				type:
				  videoType === "youtube" ? "video/youtube" :
					videoType === "wistia" ? "video/wistia" :
					  videoType === "video/mp4" ? "video/mp4" :
						videoType === "application/x-mpegURL" ? "application/x-mpegURL" :
						  "application/x-mpegURL",
				src:
				  videoType === "youtube"
					? "https://www.youtube.com/watch?v=" + videoUrl
					: videoType === "wistia"
					  ? "http://fast.wistia.com/embed/iframe/" + videoUrl
					  : videoUrl,
			  });
			if (videoPoster) vidPlayer.poster(videoPoster);

			vidPlayer.on("ready", function () {
				vidPlayer.play();
			});
			$(".popup-video").animate({
					opacity: "1",
				},
				500
			);
		},
		beforeClose: function () {
			$(".popup-video").hide();
			$("html").removeClass("is--videopopup");
			$("body").removeClass("is--videopopup");
			videojs("lightboxVideo").dispose();
		},
	});
}
function dataSrc() {
	if (winWidth < 600) {
		$("[data-mobile-src]").each(function () {
			var thisSrc = $(this).attr("data-mobile-src");
			$(this).attr("src", thisSrc);
		});
		$("[data-mobile-poster]").each(function () {
			var thisSrc = $(this).attr("data-mobile-poster");
			$(this).attr("poster", thisSrc);
		});
	} else {
		$("[data-desktop-src]").each(function () {
			var thisSrc = $(this).attr("data-desktop-src");
			$(this).attr("src", thisSrc);
		});
		$("[data-desktop-poster]").each(function () {
			var thisSrc = $(this).attr("data-desktop-poster");
			$(this).attr("poster", thisSrc);
		});
	}
}
function videoPlayerInit() {
	$(".my-video-js").each(function (index) {
		var thisId = $(this).attr("id");
		$(this).addClass("video-js");
		if (!thisId == "") {
			thisId = "video-id-" + index;
			$(this).attr("id", thisId);
		}
		var player = videojs(thisId);
	});
}
function videoOnHover() {
	$(document).on("click touchstart", ".vjs-poster", function () {
		$(this).parent().parent().find("> button").hide();
	});

	$(document).on("click touchstart", ".vjs-iframe-blocker", function () {
		$(this).parent().parent().parent().find("> button").hide();
		var video = $(this).parent().parent().attr("id");
		var player = videojs(video);
		player.play();
	});
	var allVideos = document.querySelectorAll(".my-video-js");
	allVideos.forEach((video) => {
		var player = videojs(video);
		player.on("play", function () {
			if (video.classList.value.includes("js-bg-video")) {
				return;
			}
			const filterVid = Array.from(allVideos).filter((vid) => vid !== video);
			filterVid.forEach((video) => {
				if (video.classList.value.includes("js-bg-video")) {
					return;
				}
				var player1 = videojs(video);
				player1.pause();
			});
		});
	});
}
function separatePopup() {
	var allVideos = document.querySelectorAll(".my-video-js");
	allVideos.forEach((video) => {
		if (video.classList.value.includes("js-bg-video")) {
			return;
		}
		var player = videojs(video);
		player.pause();
	});
}
function closeVideoOnClick() {
	const newsDetailBox = document.querySelector('.video-img-box')
	const closeIc = newsDetailBox?.querySelector('.js-vid-close-icon')
	const videoJsDiv = newsDetailBox?.querySelector('.my-video-js')
	const videoJsTechDiv = newsDetailBox?.querySelector('.vjs-tech')
	const videoJsControls = videoJsDiv?.querySelector('.vjs-control-bar')
	const videoJsPoster = videoJsDiv?.querySelectorAll('.vjs-poster')
	let videoPlayer;
	if (videoJsTechDiv) {

		videoPlayer = videojs(videoJsTechDiv);
	}
	closeIc?.addEventListener('click', () => {
		closeIc.classList.remove('show-icon')
		videoPlayer.currentTime(0);
		videoPlayer.pause();
		videoJsPoster.forEach(poster => {
			poster.style.display = 'block';
			videoJsControls.style.display = 'none';
			videoJsControls.style.display = 'none';
		})
	})

	if (videoPlayer) {
		videoPlayer.on('play', () => {
			const videoJsPoster = videoJsDiv?.querySelectorAll('.vjs-poster')
			closeIc.classList.add('show-icon')
			videoJsPoster.forEach(poster => {
				poster.style.display = 'none';
				videoJsControls.style.display = 'flex';
			})
		});
	}
}
// Accordion
function initAccordion(){
	$('body').on('click', '.head', function(e) {
		let $item = $(this).closest('.ac-item');
		if ($item.hasClass('active')) {
			$item.removeClass('active').find('.content-box').stop().slideUp();
		} else {
			$('.ac-item').removeClass('active').find('.content-box').stop().slideUp();
			$item.addClass('active').find('.content-box').stop().slideToggle();
		}
	});
    // Remove active class on accordion close
    $('.accordion').on('hidden.bs.collapse', function(e) {
        $(e.target).closest('.accordion-item').removeClass('active');
    });
}
function alertSlider() {
	$('.js-alert-slider').each(function () {
		$(this).slick({
			slidesToShow: 1,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			autoplay: false,
  			autoplaySpeed: 5000,
			infinite: true,
		});
	})
}
function menuOpen() {
	$('.hamburger').on('click', function() {
		$('html').toggleClass('menu--open');
	  });
	 
	  $('.js-close').on('click', function() {
		$('body').addClass('alert--closed');
	  });
}
function scrollTop() {
	var lastScrollTop = 0;
	$(window).scroll(function () {
	  var st = $(this).scrollTop();
	  if (st > lastScrollTop) {
		$("body").removeClass("scrollTop");
		$("body").addClass("bodyScrolled");
	  } else {
		$("body").addClass("scrollTop");
		$("body").removeClass("bodyScrolled");
	  }
	  if (st === 0) {
		$("body").removeClass("scrollTop");
		$("body").removeClass("bodyScrolled");
	  }
  
	  lastScrollTop = st;
	});
}
function intAccordionTabs(){
	$(".ac-title").on("click", function(e) {
		e.preventDefault();
		var $this = $(this);
		var dataIndex = $(this).attr('data-index'); // Get the data-index attribute
		
		if (!$this.hasClass("ac-active")) {
			$(this).parent().parent().parent().find(".image-box .ac-image").removeClass('show');
			$(this).parent().parent().find(".ac-content").slideUp(400);
			$(this).parent().parent().find(".ac-title").removeClass("ac-active");
			$(this).parent().parent().find('.ac-arrow').removeClass('ac-icon-active');   
	
		// Add index-based class to the respective image-box item
			$(this).parent().parent().parent().find(".image-box .ac-image").eq(dataIndex).addClass('show');
			$this.addClass("ac-active");
			$this.next().slideDown();
			$('.ac-arrow', this).addClass('ac-icon-active');
		}
	});
}
function accordionhoverState() {
	$(".card-box").mouseenter(function () {
		var itemNo = $(this).index();
		$(".card-box").removeClass("active");
		$(this).parent().removeClass("hover0");
		$(this).parent().removeClass("hover1");
		$(this).parent().removeClass("hover2");    
		$(this).addClass("active");
		$(this).parent().addClass("hover" + itemNo);
	});
}
function accordionClickState() {
	$('.card-box')
		.on('taphold', {delay: 50}, function () {
		// taphold actions
		var itemNo = $(this).index();
		$(".card-box").removeClass("active");
		$(this).parent().removeClass("hover0");
		$(this).parent().removeClass("hover1");
		$(this).parent().removeClass("hover2");    
		$(this).addClass("active");
		$(this).parent().addClass("hover" + itemNo);
		})
		.on('click', function () {
		// normal actions
		var itemNo = $(this).index();
		$(".card-box").removeClass("active");
		$(this).parent().removeClass("hover0");
		$(this).parent().removeClass("hover1");
		$(this).parent().removeClass("hover2");    
		$(this).addClass("active");
		$(this).parent().addClass("hover" + itemNo);
	});
}
function onMouseHover() {
	$('.img-grid').mouseenter(function () {
	  $(this).addClass('expand');
	  $('.img-grid').not(this).addClass('contract');
	});
	$('.img-grid').mouseleave(function () {
	  $(this).removeClass('expand');
	  $('.img-grid').not(this).removeClass('contract');
	});
}
function eventList()  {
	$(".event_title").on("click", function(event) {
		event.preventDefault();

		// Remove 'active' class from all .event_title and .eventSlider panes
		$(".event_title").removeClass("active");
		$(".eventSlider").removeClass("show");

		// Add 'active' class to the clicked .event_title
		$(this).addClass("active");

		// Get the target from the href attribute and show the corresponding tab content
		var targetID = $(this).attr("href");
		$(targetID).addClass("show");

		// Initialize the carousel slider for the active tab
		var $slider = $(targetID).find(".sec-slider1");

		// Check if slick is already initialized, then destroy it to reinitialize
		if ($slider.hasClass("slick-initialized")) {
		$slider.slick("unslick");
		}

		// Call the loadInitiativeCarousel function to initialize the slider
		loadInitiativeCarousel($slider);
	});
};

function initLwBoxHover(){
    const lwBox =  $('.lw-item');
    if(lwBox.length > 0 && $(window).width() > 991){
        lwBox.on('mouseenter click', function() {
            // Get the data-index of the hovered/clicked .listBox
            lwBox.removeClass('active');
            $(this).addClass('active');
        });
    }else{
        lwBox.removeClass('active');
    }
}

function initlwSlider() {
	$('.js-lw-slider').each(function () {
		if (winWidth < 992) {
			$(this).slick({
				rtl: isRTL,
				dots: true,
				arrows: false,
				draggable: true,
				autoplay: false,
				infinite: false,
				slidesToShow: 2,
				slidesToScroll: 1,
				responsive: [
					{
					  breakpoint: 601,
					  settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					  }
					} 
				  ]
			});
		}
	})
}

function initnewsUpdateSlider() {
	$('.js-newsUpdate-slider').each(function () {
		$(this).slick({
			infinite: false,
			autoplay: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			draggable: true,
			rtl: isRTL,
			arrows: true,
			dots: false,
			responsive: [
				{
					breakpoint: 992,
					settings: {
					  slidesToShow: 2,
					  slidesToScroll: 1,
					}
				},
				{
				  breakpoint: 601,
				  settings: {
					dots: true,
					slidesToShow: 1,
					slidesToScroll: 1,
				  }
				},
			]
		});
	})
}

function scrolltosection() {
	const hash = window.location.hash.replace('#', '');
    if (hash) {
		const target = $("body").find(`[data-name='${hash}']`)
		if (target) {
			window.scrollTo({
				top: target.offset().top - 100, // Adjust for header height (e.g., 50px)
				behavior: 'smooth'
			});
		}
    }
}

function sliderHeight() {
    // Allow DOM changes to settle before calculation
    setTimeout(function () {
        // Select each slider group separately
        $('.js-location-slider, .js-tips-slider, .js-intrest-slider, .js-overnight-slider, .js-venue-slider, .js-staying-slider, .js-newsUpdate-slider').each(function () {
            var maxHeight = 0;

            // Find the tallest postBox within the current slider
            $(this).find('.postBox, .card-postBox').each(function () {
                var thisHeight = $(this).outerHeight(); // Get height including padding
                if (thisHeight > maxHeight) {
                    maxHeight = thisHeight;
                }
            });

            // Apply the tallest height to all postBox elements in the current slider
            $(this).find('.postBox, .card-postBox').height(maxHeight);
        });
    }, 1000);
}


function jsMapBox() {
	$('.map-pin').click(function() {
        const name = $(this).data('name');
        
        // Remove 'show' class from all detailBoxes
        $('.detailBox').removeClass('show');
        
        // Add 'show' class to the corresponding detailBox
        $(`.detailBox[data-name="${name}"]`).addClass('show');
        
        // Remove 'active' class from all map-pins
        $('.map-pin').removeClass('active');
        
        // Add 'active' class to the clicked map-pin
        $(this).addClass('active');
		$('html').addClass('mapDetail--open');
    });
	$('.detailBox .postDetail').click(function() {
        const name = $(this).data('name');

        // Remove 'detail--show' class from all detailBox1 elements
        $('.detailBoxBar').removeClass('detail--show');

        // Add 'detail--show' class to the corresponding detailBox1
        $(`.detailBoxBar[data-name="${name}"]`).addClass('detail--show');
    });
	$('.close-btn').click(function() {
		$('.detailBox').removeClass('show');
		$('.map-pin').removeClass('active');
		$('.detailBoxBar').removeClass('detail--show');
		$('html').removeClass('mapDetail--open');
	})
	$('.back-btn').click(function() {
		$('.detailBoxBar').removeClass('detail--show');
	});
};

function initNewsletterPopup(){
	$(".js-newsletter").click(function (){
		$(".subs-wrapper").addClass("popup--open");
		$('html').addClass('is--subscribe-popup')
	});
	$(".js-close").click(function (){
		$(".subs-wrapper").removeClass("popup--open");
		$('html').removeClass('is--subscribe-popup')
	});
}

function filterBar(){
	$(".filter--wrapper").click(function (){
		$('.filterOption--box').toggleClass("filter--show");
		if (winWidth < 601) {
		$('html').addClass("fliter--open")
		}
	});
	$(".filterOption--box ul li").click(function () {
		$(".filterOption--box ul li").removeClass("active");
		$(this).addClass("active");
		if (winWidth < 601) {
			$('.filterOption--box').removeClass("filter--show");
			$('html').removeClass("noScroll")
			$('html').removeClass("fliter--open")
		}
	});
	$(".js-close").click(function (){
		$('.filterOption--box').removeClass("filter--show");
		$('html').removeClass("noScroll")
		$('html').removeClass("fliter--open")
	});
}

function secMapHeight(){
	// Get the footer height
	var footerHeight = $('.footer').outerHeight();
	if (winWidth > 600) {
		$('.sec-map').css('min-height', `calc(100vh - ${footerHeight}px)`);
	}
};


function scrollBar(){
	if($(".js_scrolled").length > 0){
		$(".js_scrolled").mCustomScrollbar();
	}
};

function scrolltoSec() {
	$('.banner--experience .btn-primary').on('click', function(event) {
		$('html, body').animate({
		  scrollTop: $('#eosb_benifits').offset().top - 50
		}, 800); // The duration in milliseconds (1000 ms = 1 second)
	});
};